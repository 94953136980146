import hrorg from './../data/ticked/models/hrorg.json'

export default ({model}) => {

    const renderPath = (path, org) => {
        if (path === org.tag) {
            return <span key={path}>{org.name}</span>
        } else {
            return renderPath(path, hrorg.contents.find(org => org.tag.startsWith(path)))
        }
    }

    return <table><tbody>
        <tr><th>Organisation</th><th>Risk</th><th>Description</th><th>Likelihood</th><th>Impact</th><th>Level</th></tr>
        {
        model.items.map( item => 
            <tr key={item.id}><td>{renderPath(item.hrorg, hrorg)}</td><td>{item.name}</td><td>{item.description}</td><td>{item.likelihood}</td><td>{item.impact}</td><td>{item.risk}</td></tr>
        )
        }
    </tbody></table>

}