
{
    "name": "Ticked Pty Ltd",
    "tag": "/",
    "contents": [
        {
            "name": "Finance",
            "tag": "/finance"
        },
        {
            "name": "Information Technology",
            "tag": "/it"
        },
        {
            "name": "Human Resources",
            "tag": "/hr"
        },
        {
            "name": "Marketing",
            "tag": "/marketing"
        },
        {
            "name": "Operations",
            "tag": "/operations"
        },
        {
            "name": "Sales",
            "tag": "/sales"
        }
    ]
}
