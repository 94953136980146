{
    "type": "index",
    "models": [
        {
            "name": "Risks",
            "id": "risks",
            "price": "$0"
        },
        {
            "name": "Products",
            "id": "ticked-products-org",
            "price": "$0"
        },
        {
            "name": "Organisation Structure",
            "id": "ticked-hr-org",
            "price": "$0"
        }
    ]
}