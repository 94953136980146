import React from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { Amplify } from 'aws-amplify'
import { Authenticator } from '@aws-amplify/ui-react'
import Layout from './components/Layout'
import Home from './pages/Home'
import Model from './pages/Model'

export default () => {

    Amplify.configure({
        Auth: {
            Cognito: {
                userPoolId: "us-east-1_yZ74xrTFB",
                userPoolClientId: "1qir0i0qmu0ru76ie1m9i02m8p",
                loginWith: {
                    email: true
                },
                signUpVerificationMethod: "code",
                userAttributes: {
                    email: {
                        required: true
                    },
                    name: {
                        required: true
                    }             
                },
                allowGuestAccess: false,
                passwordFormat: {
                    minLength: 8,
                    requireLowercase: true,
                    requireUppercase: true,
                    requireNumbers: true,
                    requireSpecialCharacters: true,
                }
            }
        }
    })

    return <Authenticator.Provider>
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Layout />}>
                    <Route index element={<Home />} />
                    <Route path="/model" element={<Model />} />
                </Route>
            </Routes>
        </BrowserRouter>
    </Authenticator.Provider>

}