{
    "type": "model",
    "id": "ticked/rio",
    "parent": "rio",
    "name": "Ticked Risks",
    "items": [
        {
            "id": 1,
            "name": "Miss the deadline to submit to council",
            "description": "The project will be delayed if the deadline to submit to council is missed.",
            "likelihood": "2",
            "impact": "4",
            "risk": "MEDIUM",
            "hrorg": "/it"
        },
        {
            "id": 2,
            "name": "Single point of failure",
            "description": "The project will be delayed if the single point of failure fails.",
            "likelihood": "4",
            "impact": "3",
            "risk": "HIGH",
            "hrorg": "/"
        },
        {
            "id": 3,
            "name": "Complexity of the project",
            "description": "The project will be delayed if the project is too complex.",
            "likelihood": "1",
            "impact": "2",
            "risk": "LOW",
            "hrorg": "/finance"
        },
        {
            "id": 4,
            "name": "Long lead time for materials",
            "description": "The project will be delayed if the materials have a long lead time.",
            "likelihood": "3",
            "impact": "1",
            "risk": "LOW",
            "hrorg": "/finance"
       }
    ]
}