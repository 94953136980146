import React, { useState, useEffect } from 'react'
import { fetchAuthSession } from '@aws-amplify/auth';

export default ({model}) => {

    const [eventQueue, setEventQueue] = useState([])
    const [token, setToken] = useState(null)

    useEffect(() => {
        fetchAuthSession()
            .then(session => setToken(session.tokens.accessToken.toString()))
    }, [])

    const sendEvent = (id, event) => {
        setEventQueue([...eventQueue, {id, id, status: 'sending', request: event, response: null}])
        fetch('https://ticked.com/event', { method: 'POST', headers: {Authorization: token}, body: JSON.stringify(event) })
        .then(response => {
            if (!response.ok) {
                setEventQueue(eventQueue.map(item => {
                    if (item.id === id) {
                        item.status = 'sent'
                        return item
                    }
                    return item
                }))
            }
            return response.json()
        })
        .then(data => {
            setEventQueue(eventQueue.map(item => {
                if (item.id === id) {
                    item.status = 'closed'
                    item.response = data
                    return item
                }
                return item
            }))
        })
    }    

    const subscribe = () => {
        sendEvent("1", {name: model.name})
    }

    return <article>
        <h2>{model.name}</h2>
        <p>{model.price}</p>
        <button onClick={subscribe}>Subscribe</button>
    </article>
}

