import { Outlet } from 'react-router-dom'
import logo from './../resources/ticked-logo.svg'
import { useAuthenticator } from '@aws-amplify/ui-react'
import { fetchUserAttributes } from '@aws-amplify/auth'
import { useEffect, useState } from 'react'

export default () => {

    const { route, user, signOut } = useAuthenticator((context) => [context.route, context.user])
    const [name, setName] = useState(null)

    useEffect(() => {
        if (route === 'authenticated') {
            fetchUserAttributes().then(attributes => {
                setName(attributes.name)
            })
        }
    }, [user, route])

    return <>
        <header>
            <ul>
                <li id="logo">
                    <a href="/"><img src={logo} alt="Ticked" /></a>
                </li>{
                    process.env.NODE_ENV === 'production' 
                        ? <li></li>
                        : route === 'authenticated' 
                            ? <>
                                <li>{name}</li>
                                <li>
                                    <a onClick={signOut}>Sign out</a>
                                </li>
                            </> 
                            : route === 'signIn' || route === 'signUp'
                                ? <li></li>
                                : <li>
                                    <a href="/model">Sign in</a>
                                </li>
                        
                }
                

            </ul>
        </header>
        <Outlet />
        <footer>
            <ul>
                <li>&copy;&nbsp;2024&nbsp;Ticked&nbsp;Pty&nbsp;Ltd (ABN&nbsp;32&nbsp;659&nbsp;531&nbsp;987). All&nbsp;rights&nbsp;reserved.</li>
            </ul>
        </footer>
    </>

}
