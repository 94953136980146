import React, { useState, useEffect } from 'react'
import { Authenticator } from '@aws-amplify/ui-react'
import index from './../data/ticked/index.json'
import risks from './../data/ticked/models/risks.json'
import Model from '../components/Model'
import Subscription from '../components/Subscription'

const loadData = () => {
    const hash = decodeURIComponent(window.location.hash.replace('#', ''))
    if (hash === '') {
        return index
    } else if (hash === 'risks') {
        return risks
    }
}

export default () => {

    const [data, setData] = useState(null)

    useEffect(() => {

        setData(loadData())

        const handleHashChange = () => {
            setData(loadData())
            console.log('change')
        }

        window.addEventListener('hashchange', handleHashChange)

        return () => {
            window.removeEventListener('hashchange', handleHashChange)
        }

    }, [])

    return <Authenticator formFields={{
        signUp: {
            "name": {
              label: 'Name:',
              placeholder: 'Your name',
              order: 1
            }
          }
    }} signUpAttributes={['name']}>{({ user }) => <section>{
            data?.type === "model" 
                ? <>
                    <div id="toolbar"><a href="/model"><span className="back"></span></a>{data.name}</div>
                    <Model model={data} />
                </>
                : <>
                    <div id="toolbar"></div>
                    <div className="cards">
                        {data?.models?.map(model => <Subscription key={model.id} model={model} />)}
                    </div>
                </>
    }</section>}</Authenticator>

}